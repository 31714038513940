import { reqProduct , reqOtherProducts ,reqSolution ,reqSpecial,reqProductDetail} from '@/api'

const state = {
    // 产品介绍
    product:[],
    // 周边配件
    otherProducts:{},
    // 解决方案
    solution:{},
    // 定制服务
    special:{},
    // 产品详情
    productDetail:{}
}

const getters = {
    // 产品介绍
    Tseries(state){
        return state.product[0]  || {}
    },
    CRseries(state){
        return state.product[1]  || {}  
    },
    Xseries(state){
        return state.product[2]  || {}  
    },
    others(state){
        return state.product[3]  || {}
    },
    // 周边配件
    otherProductsList(state){
        return state.otherProducts.content || {}
    },
    // 解决方案
    solutionInfo(state){
        return state.solution.channel_content
    },
    solutionList(state){
        return state.solution.content
    },
    // 定制服务
    specialInfo(state){
        return state.special.channel_content || [{}]
    },
    specialList(state){
        return state.special.content || [{},{}]
    },
    // 产品详情
    productDetailContent(state){
        return state.productDetail.content || {}
    }
}

const actions = {
    // 产品介绍
    async getProduct({ commit }) {
        let res = await reqProduct()
        if (res.code == 200) {
            commit('GETPRODUCT', res.content.content)
        }
    },
    // 周边配件
    async getOtherProducts({ commit }, query = {}) {
        let res = await reqOtherProducts(query)
        if (res.code == 200) {
            commit('GETOTHERPRODUCTS', res.content)
            // console.log(res.content);
        }
    },
    // 解决方案
    async getSolution({ commit }, query = {}) {
        let res = await reqSolution(query)
        if (res.code == 200) {
            commit('GETSOLUTION', res.content)
        }
    },
    // 定制服务
    async getSpecial({ commit }) {
        let res = await reqSpecial()
        if (res.code == 200) {
            commit('GETSPECIAL', res.content)
        }
    },
    // 产品详情
    async getProductDetail({ commit }, query = {}) {
        let res = await reqProductDetail(query)
        if (res.code == 200) {
            commit('GETPRODUCTDETAIL', res.content)
        }
    },
}

const mutations = {
    // 产品介绍
    GETPRODUCT(state,product){
        state.product = product
    },
    // 周边配件
    GETOTHERPRODUCTS(state, otherProducts) {
        state.otherProducts = otherProducts
    },
    // 解决方案
    GETSOLUTION(state, solution) {
        state.solution = solution
    },
    // 定制服务
    GETSPECIAL(state, special) {
        state.special = special
    },
    // 产品详情
    GETPRODUCTDETAIL(state,productDetail){
        state.productDetail = productDetail
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}