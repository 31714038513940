<template>
	<div class="type-nav" :class="bgClass" @mouseenter="flag && changeLogoBgFontColor()"
		@mouseleave="flag && reverseLogoBgFontColor()">
		<div class="container">
			<div class="white-logo">
				<!-- 1.点击logo跳转到首页 -->
				<router-link to="/home">
					<img src="@/assets/images/footer/bluelogo.png" alt="" />
				</router-link>
			</div>
			<!-- <p>{{ this.cityocTop }}</p> -->
			<!-- 一级菜单 -->

			<div class="sort" @click="goFirstRoute">
				<div v-show="isShowMenu" class="item" @mouseenter="changeId(c1.id)" @mouseleave="leaveId"
					v-for="(c1, index) in channel_list" :key="c1.id" :data-id="c1.id" :data-url="c1.url">
					<h3 :data-id="c1.id" :data-url="c1.url" @click="changeIndex2(index)"
						:class="{ hide_h3: currentIndex2 == index }">
						<a :id="blackClass">
							<span :class="{ cur: currentId == c1.id }" :data-id="c1.id"
								:data-url="c1.url">{{ c1.title }}</span>
							<i class="el-icon-plus" :class="{
                  hide_plus: currentIndex2 == index,
                }">
							</i>
						</a>
					</h3>

					<h3 class="click_h3" @click="changeIndex3(index)" :class="{ show_h3: currentIndex2 == index }">
						<span>{{ c1.title }}</span>
						<i class="el-icon-minus" :class="{
                show_plus: currentIndex2 == index,
              }">
						</i>
					</h3>
					<!-- 二级菜单 -->
					<div class="item-list">
						<div class="subitem">
							<dl v-for="c2 in c1.son" :key="c2.id" :data-id="c2.id" :data-url="c2.url">
								<div class="bg">
									<img :src="$store.state.imgBaseUrl + c2.image" alt="" />
								</div>
								<div class="subitemIcon">
									<img :src="$store.state.imgBaseUrl + c2.icon" :data-id="c2.id" :data-url="c2.url" />
								</div>
								<dt :data-id="c2.id" :data-url="c2.url" :class="{ show: currentIndex2 == index }">
									<a :data-id="c2.id" :data-url="c2.url">{{ c2.title }}</a>
								</dt>
							</dl>
						</div>
					</div>
				</div>

				<div class="serach_wrap" v-show="!isShowMenu" key="2">
					<el-autocomplete ref="autoInput" class="inline-input" v-model="keyword"
						:fetch-suggestions="querySearch" placeholder="请输入搜索关键词" :popper-append-to-body="false"
						@select="goSearch" @keyup.enter.native="goSearch"
						@input="changeStyle('block', '.el-autocomplete-suggestion')"
						@keyup="changeStyle('block', '.el-autocomplete-suggestion')">
						<el-button slot="append" icon="el-icon-search" @click="goSearch"></el-button>
					</el-autocomplete>
					<!-- 
            fetch-suggestions:返回输入建议
            @select:点击选中建议项时触发
           -->
				</div>
			</div>
			<!-- 手机端折叠展开图标 -->
			<div class="show_menu" @click="showMenu">
				<img src="@/assets/images/topnav/more.svg" alt="" />
			</div>
			<div class="hide_menu" @click="hideMenu">
				<img src="@/assets/images/topnav/X.svg" alt="" />
			</div>

			<div class="search" :class="iconClass">
				<i class="el-icon-search" @click="hideSort" v-show="isShowMenu"> </i>
				<!-- PC关闭按钮 -->
				<i class="el-icon-close PC_close" @click="hidePcSearch" v-show="!isShowMenu"></i>
				<!-- phone关闭按钮 -->
				<i class="el-icon-search phone_close" @click="hidePhoneSearch" v-show="!isShowMenu"></i>
			</div>

			<div class="maplogo" :class="iconClass">
				<i class="iconfont icon-map"></i>
			</div>

			<!-- [选择地区] -->
			<div class="chooseArea" :class="hoverClass">
				<el-cascader size="small" :options="options" v-model="selectedOptions" @change="handleChange">
				</el-cascader>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from "vuex";
	import {
		provinceAndCityData,
		regionData,
		provinceAndCityDataPlus,
		regionDataPlus,
		CodeToText,
		TextToCode,
	} from "element-china-area-data";
	export default {
		name: "TopNav",
		data() {
			return {
				restaurants: [],
				isShowMenu: 1,
				keyword: "",
				// 切换一级分类标题下划线
				currentId: -1,
				currentIndex2: -1,
				currentIndex3: -1,
				// 切换一级分类标题颜色
				blackClass: "",
				// 切换map/search图标
				iconClass: "",
				// 易数logo
				logoClass: "#fff",
				// 背景
				bgClass: "",
				flag: true,
				topNavState: [
					"product",
					"news-detail",
					"other-products",
					"product-detail",
				],
				// 选择地区
				hoverClass: "",
				options: provinceAndCityData,
				selectedOptions: [],
			};
		},
		mounted() {
			this.removeEvent();
			this.changePlaceHolder();

			// 把loadAll方法中的的搜索建议赋值到data中
			this.restaurants = this.loadAll();
		},
		watch: {
			// 监视路由跳转，根据当前位置决定header显示状态
			$route: {
				handler(route) {
					this.removeEvent();
					// 无论是否搜索，路由变化就显示导航，隐藏搜索
					this.showSort();

					let mobile_flag = this.$isMobile();

					if (mobile_flag) {
						// 跳转页面隐藏一级菜单
						this.hideMenu();
						this.hideSecondMenu();
					}

					let id = route.query.channel_id

					// if (mobile_flag) {
					// 	if (id == 27) {
					// 		// 路由不变事业背景锚点到加盟申请
					// 		this.$bus.$emit('requestScroll')
					// 	} else if (id == 26) {
					// 		// 路由不变事业背景锚点到phone加盟优势
					// 		this.$bus.$emit('phoneAdvantageScroll')
					// 	} else if (id == 41) {
					// 		this.$bus.$emit('contactUsScroll')
					// 	}
					// } else {
					// 	if (id == 27) {
					// 		// 路由不变事业背景锚点到加盟申请
					// 		this.$bus.$emit('requestScroll')
					// 	} else if (id == 26) {
					// 		// 路由不变事业背景锚点到加盟优势
					// 		this.$bus.$emit('pcAdvantageScroll')
					// 	} else if (id == 41) {
					// 		// 路由不变事业背景锚点到联系我们
					// 		this.$bus.$emit('contactUsScroll')
					// 	} else if (id == 40) {
					// 		// 路由不变事业背景锚点到城市运营中心
					// 		this.$bus.$emit('aboutUsCityoc')
					// 	}
					// }
				},
			},
		},
		computed: {
			...mapGetters(["channel_list"]),
		},
		methods: {
			// queryString:查询字符串
			querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				/*
				  1、如果用户输入了查询字符串，则对候选值集合进行过滤，过滤条件是：
				  忽略大小写
				  以输入的查询字符串开头
				  2、如果想改为只要包括了查询字符串就显示，可以将上文中的 === 0改为 !== -1
				*/
				var results = queryString ?
					restaurants.filter(this.createFilter(queryString)) :
					restaurants;
				// 调用 callback 返回建议列表的数据到页面中
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
					// toLowerCase()：把字符串转换为小写
					// "hello".indexOf('e'):查找e在hello中首次出现的位置(0开始)，没有返回-1
					// restaurant的value值在queryString中是否是排在第一位，是返回true,不是返回false
					return (
						restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !==
						-1
					);
				};
			},
			loadAll() {
				return [{
						value: "LT10智慧化多功能急救教学机"
					},
					{
						value: "LT20智慧化多功能急救小型教学机"
					},
					{
						value: "ST10智慧化救护E站"
					},
					{
						value: "CR20急救教育智慧课堂"
					},
					{
						value: "急救教育智慧大脑数据服务系统"
					},
				];
			},
			// 隐藏导航菜单
			hideSort() {
				var mobile_flag = this.$isMobile(); // true为手机端，false为PC端
				// 只在手机上点击search图标才会展开菜单
				if (mobile_flag) {
					this.showMenu();
				}

				this.isShowMenu = !this.isShowMenu;

				// 必须在input更新到页面后才能获取焦点，$nextTick基于更新后的DOM状态进行操作
				this.$nextTick(() => {
					this.$refs.autoInput.focus();
				});
			},
			hidePcSearch() {
				this.isShowMenu = !this.isShowMenu;
			},
			hidePhoneSearch() {
				// this.isShowMenu = 0;
				this.hideMenu();
			},

			// 显示导航菜单
			showSort() {
				this.isShowMenu = 1;
			},
			//根据传进来的状态改变建议输入框的状态（展开|隐藏）
			changeStyle(status, className) {
				let dom = document.querySelectorAll(className);
				dom[0].style.display = status;
			},
			// 跳转到Search
			goSearch() {
				// 跳转到结果页隐藏建议
				this.changeStyle("none", ".el-autocomplete-suggestion");
				// 显示导航菜单
				// this.showSort()
				// 跳转传参
				this.$router.push({
					name: "search",
					// params: {
					//   // 如果传递空，则路径会有问题，undefined可以解决
					//   keyword: this.keyword || undefined
					// },
					query: {
						keyword: this.keyword,
					},
				});
				// 清空查询字符串
				this.keyword = "";
			},
			// 手机端展开菜单
			showMenu() {
				document.querySelector(".show_menu").style.display = "none";
				document.querySelector(".hide_menu").style.display = "block";
				document.querySelector(".sort").style.height = "1500px";
			},
			// 手机端折叠菜单
			hideMenu() {
				document.querySelector(".show_menu").style.display = "block";
				document.querySelector(".hide_menu").style.display = "none";
				document.querySelector(".sort").style.height = "0";

				// 从搜索页面切换到导航
				this.isShowMenu = 1;
				this.hideSecondMenu();
			},
			// 折叠二级菜单
			hideSecondMenu() {
				this.currentIndex2 = -1;
			},
			removeEvent() {
				// 路由跳转到不需要鼠标移入移出事件的组件，解绑事件
				this.flag = false;
				// 强制显示蓝色logo/白色背景/字体黑色
				this.bgClass = "bgColor";
				this.blackClass = "blackA";
				// 图标变色
				this.iconClass = "blackIcon";
				this.logoClass = "#1A7DFF";
				this.hoverClass = "hoverChooseArea";
			},
			changeIndex2(index) {
				this.currentIndex2 = index;
				// this.currentIndex3 = -1;
			},
			changeIndex3(index) {
				// this.currentIndex3 = index;
				this.currentIndex2 = -1;
			},
			// 切换一级分类标题下划线和分割线
			changeId(id) {
				this.currentId = id;
				// 移入指定一级分类标题，显示分割线
				let showSplitArr = [19, 24, 37];
				let showSplit = showSplitArr.includes(id);
				if (showSplit) {
					document.querySelector(".type-nav").style.borderBottom =
						"solid rgb(219, 219, 219) 1px";
				}
				// this.flag = false
			},
			leaveId() {
				this.currentId = -1;
				document.querySelector(".type-nav").style.borderBottom =
					"solid transparent 1px";
			},
			// 切换logo\search\map图标、切换【选择地区】颜色、切换背景、切换一级分类标题颜色
			changeLogoBgFontColor() {
				// 切换背景
				this.bgClass = "bgColor";
				// 切换一级分类标题颜色
				this.blackClass = "blackA";
				// 切换logo\search\map图标
				this.iconClass = "blackIcon";
				this.logoClass = "#1A7DFF";
				this.hoverClass = "hoverChooseArea";
			},
			reverseLogoBgFontColor() {
				this.bgClass = "";
				this.blackClass = "";
				// 图标变色
				this.iconClass = "";
				this.logoClass = "#fff";
				this.hoverClass = "";
			},
			// 路由跳转
			goFirstRoute(event) {
				let element = event.target;
				let {
					url,
					id
				} = element.dataset;
				// 如果含有url,必定是a,设置后台url的值，为空则点击无效不跳转
				let mobile_flag = this.$isMobile(); // true为手机端，false为PC端

				if (url) {
					let location = {
						name: url
					};
					let query = {
						channel_id: id
					};
					// query.channel_id = id
					location.query = query;
					this.$router.push(location);
				}

				if (mobile_flag) {
					if (id == 27) {
						// 路由不变事业背景锚点到加盟申请
						this.$bus.$emit('requestScroll')
					} else if (id == 26) {
						// 路由不变事业背景锚点到phone加盟优势
						this.$bus.$emit('phoneAdvantageScroll')
					} else if (id == 41) {
						this.$bus.$emit('contactUsScroll')
					}
				} else {
					if (id == 27) {
						// 路由不变事业背景锚点到加盟申请
						this.$bus.$emit('requestScroll')
					} else if (id == 26) {
						// 路由不变事业背景锚点到加盟优势
						this.$bus.$emit('pcAdvantageScroll')
					} else if (id == 41) {
						// 路由不变事业背景锚点到联系我们
						this.$bus.$emit('contactUsScroll')
					} else if (id == 40) {
						// 路由不变事业背景锚点到城市运营中心
						this.$bus.$emit('aboutUsCityoc')
					}
				}
			},
			handleChange(value) {
				console.log(value);
			},
			// 修改input提示文字
			changePlaceHolder() {
				document.querySelector(".chooseArea .el-input__inner").placeholder =
					"[选择地区]";
			},
		},
	};
</script>

<style lang="less" scoped>
	@import "@/assets/css/TopNav.less";
</style>
