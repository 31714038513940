import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'lib-flexible'

// swiper使用：
// 1.下载：npm i swiper@5.4.5 vue-awesome-swiper@3.1.3
// 引入VueAwesomeSwiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 2.引入elementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 3.引入axios
import Axios from 'axios';
//将axios挂在到Vue的实例上
Vue.prototype.$axios = Axios

Vue.config.productionTip = false

// 4.引入插件
import plugins from './plugins/scrollPlugins'
// 注册插件
Vue.use(plugins)

// 5.引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

//引入表单验证插件
import '@/plugins/joinUsValidate'

// 分页器
import Pagination from '@/components/Pagination'
Vue.component(Pagination.name, Pagination)

import VueLazyload from 'vue-lazyload'
import defaultImg from '@/assets/images/default.jpg'
Vue.use(VueLazyload, {
	loading: defaultImg
})

import progressive from 'progressive-image/dist/vue'
import 'progressive-image/dist/index.css'

Vue.use(progressive, {
	removePreview: true,
	scale: true
})


new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this
	}
}).$mount('#app')
