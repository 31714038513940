import { reqCommon } from '@/api'

const state = {
    commonData:{},
}

const getters = {
    channel_list(state){
        return state.commonData.channel_list || []
    },
    site_head(state){
        return state.commonData.site_head  || {}
    },
    site_footer(state){
        return state.commonData.site_footer  || {}
    },
}

const actions = {
    async getCommon({ commit }) {
        let result = await reqCommon()
        // console.log(result);
        if (result.code == 200) {
            commit('CETCOMMON', result.content)
        }
    }
}

const mutations = {
    CETCOMMON(state,value){
        state.commonData = value
        // console.log('common仓库');
        // console.log(state.commonData);
    }
}

export default {
    // namespaced:true,
    state,
    mutations,
    actions,
    getters
}