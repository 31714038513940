<template>
  <div>
    <div class="footer_wrap">
      <div class="footer">
        <div class="main_content">
          <div class="content_item logo_wrap">
            <router-link to="/home">
              <img src="@/assets/images/footer/white_logo.png" alt="" />
            </router-link>
          </div>
          <div class="content_item center_list">
            <ul class="sort" @click="goFirstRoute">
              <li class="item" v-for="c1 in center_list" :key="c1.id">
                <h3>{{ c1.title }}</h3>
                <ul class="item_list" v-for="c2 in c1.son" :key="c2.id">
                  <li class="subitem" :data-id="c2.id" :data-url="c2.url">
                    {{ c2.title }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <ul class="content_item contact_wrap">
            <li class="wechat_webo">
              <div class="weichatIcon">
                <img src="@/assets/images/footer/weChat.png" alt="" />
                <div class="weichatQRcodeWrap">
                  <img src="@/assets/weChat.png" alt="" />
                </div>
              </div>
              <div class="weiboIcon">
                <img src="@/assets/images/footer/weibo.png" alt="" />
                <div class="weichatQRcodeWrap">
                  <img src="@/assets/weibo.png" alt="" />
                </div>
              </div>
            </li>
            <li class="phone">
              <div class="phoneIcon">
                <img src="@/assets/images/footer/phone.png" />
              </div>
              <span>18958156661</span>
            </li>
            <li class="email">
              <div class="emailIcon">
                <img src="@/assets/images/footer/email.png" />
              </div>
              <span>2682352816@qq.com</span>
            </li>
          </ul>
        </div>
        <ul class="footer_bottom">
          <a class="item1">Copyright ©www.ysjjjy.com</a>
          <a
            class="item2"
            href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
            target="_blank"
            >2022浙ICP备17043646号-5</a
          >
          <a
            class="item3"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012620"
            target="_blank"
          >
            <img src="@/assets/images/footer/beian.png" alt="" />
            <p>浙公网安备 33010802012620号</p>
          </a>
        </ul>
      </div>
    </div>

    <!-- phone -->
    <div class="phone_footer">
      <ul class="contact_wrap">
        <li class="phoneIcon_text">
          <img src="@/assets/images/footer/phone.png" />
          <span>联系电话</span>
        </li>
        <li class="phone_number">18958156661</li>
        <li class="online_time">周一到周日 8:00—21:00</li>
        <li class="icon_wrap">
          <img src="@/assets/images/footer/weChat.png" />
          <img src="@/assets/images/footer/weibo.png" />
          <img src="@/assets/images/footer/email.svg" />
        </li>
      </ul>
      <ul class="sort" @click="goFirstRoute">
        <li class="item" v-for="(c1, index) in center_list" :key="c1.id">
          <h3
            @click="changeIndex2(index)"
            :class="{ hide_h3: currentIndex2 == index }"
          >
            <span>{{ c1.title }}</span>
            <i
              class="el-icon-arrow-up"
              :class="{
                hide_up: currentIndex2 == index,
              }"
            ></i>
          </h3>
          <!-- 点击后的h3 -->
          <h3
            class="click_h3"
            @click="changeIndex3(index)"
            :class="{ show_h3: currentIndex2 == index }"
          >
            <span>{{ c1.title }}</span>
            <i
              class="el-icon-arrow-down"
              :class="{ show_up: currentIndex2 == index }"
            ></i>
          </h3>
          <div class="item_list">
            <div class="subitem">
              <dl v-for="c2 in c1.son" :key="c2.id">
                <dt
                  :class="{ show: currentIndex2 == index }"
                  :data-id="c2.id"
                  :data-url="c2.url"
                >
                  <a :data-id="c2.id" :data-url="c2.url">{{ c2.title }}</a>
                </dt>
              </dl>
            </div>
          </div>
        </li>
      </ul>
      <ul class="footer_bottom">
        <li class="item1">
          <a
            class="icp"
            href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
            target="_blank"
            >2022浙ICP备17043646号-5
          </a>
          <img src="@/assets/images/footer/beian.png" alt="" />
          <a
            class="item3"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012620"
            target="_blank"
          >
            <p>浙公网安备 33010802012620号</p>
          </a>
        </li>
        <li class="item2"><a>Copyright ©www.ysjjjy.com</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      center_list: [
        {
          id: 1,
          title: "产品中心",
          son: [
            {
              id: 50,
              title: "T系列",
              url: "product",
            },
            {
              id: 51,
              title: "CR系列",
              url: "product",
            },
            {
              id: 52,
              title: "大数据系统",
              url: "product",
            },
          ],
        },
        {
          id: 5,
          title: "服务支持",
          son: [
            {
              id: 6,
              title: "售后服务",
              url: "about",
            },
            {
              id: 7,
              title: "联系我们",
              url: "about",
            },
          ],
        },
        {
          id: 8,
          title: "关于我们",
          son: [
            {
              id: 38,
              title: "关于易数",
              url: "about",
            },
            {
              id: 39,
              title: "荣誉资质",
              url: "honor",
            },
            {
              id: 27,
              title: "加盟申请",
              url: "business",
            },
          ],
        },
      ],
      currentIndex2: -1,
      isShowHover: true,
    };
  },
  computed: {
    //
    ...mapGetters(["site_footer"]),
  },
  methods: {
    goFirstRoute(event) {
      let element = event.target;
      let { url, id, title } = element.dataset;
      if (url) {
        let location = { name: url };
        let query = { channel_id: id };
        location.query = query;
        this.$router.push(location);

        // 跳转页面隐藏菜单
        this.hideSecondMenu();
      }
      // 加盟申请,40
      if (id == 27) {
        this.$bus.$emit("requestScroll");
        // 售后+联系
      } else if (id == 6 || id == 7) {
        this.$bus.$emit("contactUsScroll");
        // T
      } else if (id == 50) {
        // this.$bus.$emit('TScroll')
        // CR
      } else if (id == 51) {
        this.$bus.$emit("CrScroll");
        // 大数据
      } else if (id == 52) {
        this.$bus.$emit("XScroll");
      }
    },
    changeIndex2(index) {
      this.currentIndex2 = index;
    },
    changeIndex3(index) {
      this.currentIndex2 = -1;
    },
    hideSecondMenu() {
      this.currentIndex2 = -1;
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/Footer.less";
</style>