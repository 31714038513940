import { reqNews, reqNewsDetail } from '@/api'

const state = {
    newsList: {},
    newsDetail: {}
}

const getters = {
    newsTopInfo(state) {
        return state.newsList.channel_content || []
    },
    tags(state) {
        return state.newsList.tags || []
    },
    newsListData(state) {
        return state.newsList.content || {}
    },
    newsDetailData(state) {
        return state.newsDetail.content || {}
    }
}

const actions = {
    async getNewsList({ commit }, query = {}) {
        let res = await reqNews(query)
        if (res.code == 200) {
            commit('GETNEWSLIST', res.content)
            // console.log(res.content);
        }
    },
    async getNewsDetail({ commit }, query) {
        let res = await reqNewsDetail(query)
        if (res.code == 200) {
            commit('GETNEWSDETAIL', res.content)
            // console.log(res.content);
        }
    }
}

const mutations = {
    GETNEWSLIST(state, newsList) {
        state.newsList = newsList
        // console.log(newsList);
    },
    GETNEWSDETAIL(state, newsDetail) {
        state.newsDetail = newsDetail
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}