import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}

VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

const routes = [
  // 1.主页
  {
    name: 'home',
    path: '/home',
    component: () => import('@/pages/FirstRoute/Home'),
    meta: { 
      show: true 
      // title:"易数网络",
      // content:{
      //   keywords:'教师培训'
      // }
    }
  },
  // 2.产品中心
  {
    name: 'product',
    path: '/product',
    component: () => import('@/pages/FirstRoute/Product'),
  },
  // 产品详情
  {
    name: 'product-detail',
    path: '/product-detail',
    component: () => import('@/pages/FirstRoute/ProductDetail'),
  },
  // 3.招商加盟
  {
    name: 'business',
    path: '/business',
    component: () => import('@/pages/FirstRoute/BusinesssCooperration'),
    // 跳转到二级路由隐藏招商加盟一级路由
    meta: { isShow: true,show: true  },
    children: [
      {
        name: 'join-us',
        path: 'join-us',
        component: () => import('@/pages/SecondRoute/JoinUs'),
        // 是否向偏移
        meta: { show: true }
      }
    ]
  },
  // 事业背景详情
  {
    name:'career-detail',
    path:'/career-detail',
    component: () => import('@/pages/FirstRoute/CareerDetail')
  },
  // 加盟优势详情
  {
    name:'advantage',
    path:'/advantage',
    component: () => import('@/pages/FirstRoute/Advantage')
  },
  // 4.产业合作
  {
    name: 'industrial',
    path: '/industrial',
    component: () => import('@/pages/FirstRoute/IndustrialCooperation'),
    meta: { show: true }
  },
  // 5.新闻动态
  {
    name: 'news',
    path: '/news',
    component: () => import('@/pages/FirstRoute/News'),
    // meta: { show: true },
    // children: [
    //   {
    //     name: 'news-detail',
    //     path: 'news-detail',
    //     component: () => import('@/pages/SecondRoute/NewsDetail'),
    //   }
    // ]
  },
  // 新闻详情
  {
    name: 'news-detail',
    path: '/news-detail',
    component: () => import('@/pages/SecondRoute/NewsDetail'),
  },
  // 6.关于我们
  {
    name: 'about',
    path: '/about',
    component: () => import('@/pages/FirstRoute/AboutUs'),
    // 跳转到二级路由隐藏关于我们一级路由
    meta: { isShow: true,show: true },
    children: [
      {
        name: 'honor',
        path:'honor',
        component: () => import('@/pages/SecondRoute/Honor'),
        meta: { show: true },
      }
    ]
  },
   // 关于我们详情
   {
    name:'aboutus-detail',
    path:'/aboutus-detail',
    component: () => import('@/pages/FirstRoute/AboutUsDetail')
  },
  // 7.搜索
  {
    name: 'search',
    path: '/search/:keyword?',
    name: 'search',
    component: () => import('@/pages/FirstRoute/Search'),
  },
  // 9.缺省页
  {
    name: 'defaultpage',
    path: '/defaultpage',
    meta: { show: true },
    component: () => import('@/pages/DefaultPage'),
  },
  // 10.解决方案
  {
    name: 'solvement',
    path: '/solvement',
    component: () => import('@/pages/FirstRoute/Solvement'),
    meta: { show: true }
  },

  // 11.周边配件
  {
    name: 'other-products',
    path: '/other-products',
    component: () => import('@/pages/FirstRoute/OtherProducts'),
    // meta: { show: true }
  },
  // 12.定制服务
  {
    name: 'special',
    path: '/special',
    component: () => import('@/pages/FirstRoute/Special'),
    meta: { show: true }
  },
  // 重定向
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base:'/dist/',
  routes
})

export default router
