// 二次封装axios
import axios from 'axios'
// 引入进度条
import nprogress from 'nprogress'
// start:开始，done：结束
// 引入进度条样式
import 'nprogress/nprogress.css'

// 创建axios实例
const requests = axios.create({
    // 基础路径
    baseURL:'/api',
    // 超过5s没响应取消请求
    timeout:5000
})

// 请求拦截器
requests.interceptors.request.use((config)=>{
    // config:配置对象，里面的headers请求头很重要
    // 进度条开始
    nprogress.start()
    return config
})

// 响应拦截器
requests.interceptors.response.use((res)=>{
    // 进度条结束
    nprogress.done()
    return res.data
},(error)=>{
    return Promise.reject(new Error('faile'))
})

// 对外暴露封装的请求
export default requests