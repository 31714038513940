import { reqHome } from '@/api'

const state = {
    homeData:[]
}

const getters = {
    videoData(state){
        return state.homeData[0]  || {}
    },
    // TseriesData(state){
    //     return state.homeData[1]  || {}
    // },
    // CRseriesData(state){
    //     return state.homeData[2]  || {}
    // },
    // XseriesData(state){
    //     return state.homeData[3]  || {}
    // },
    recommendProducts(state){
        return state.homeData[1]  || {}
    },
    solvementData(state){
        return state.homeData[2] || {}
    },
    businessData(state){
        return state.homeData[3] || {}
    },
    industrialData(state){
        return state.homeData[4] || {}
    },
    newsData(state){
        return state.homeData[5] || {}
    },
}

const mutations = {
    GETHOME(state,value){
        state.homeData = value
        // console.log("mutations接收数据");
    }
}

const actions = {
    async getHome({ commit }) {
        let res = await reqHome()
        if (res.code == 200) {
            // console.log("home仓库actions发请求");
            commit('GETHOME', res.content.content)
        }
    }
}

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters
}