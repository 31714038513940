import { reqIndustrial } from '@/api'

const state = {
    industrial:[]
}

const getters = {
    cooperationTopInfo(state){
        return state.industrial[0]
    },
    cooperationContent(state){
        return state.industrial[1]
    },
    joiningProcess(state){
        return state.industrial[2]
    },
}

const mutations = {
    GETINDUSTRIAL(state,industrial){
        state.industrial = industrial
    }
}

const actions = {
    async getIndustrial({ commit }) {
        let res = await reqIndustrial()
        if (res.code == 200) {
            commit('GETINDUSTRIAL', res.content.content)
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}