import Vue from 'vue'
import VeeValidate from 'vee-validate'
import zh_CN from "vee-validate/dist/locale/zh_CN";
Vue.use(VeeValidate)

VeeValidate.Validator.localize('zh-CN', {
    messages: {
        ...zh_CN.messages, // 转化为中文
        // is: (field) => `${field}必须与密码相同`, // 修改内置规则的 message，让确认密码和密码相同
    },
    attributes: {
        name:'姓名',
        phone: "手机号", // 将校验规则phone替换为手机号
        main_product: "主营产品",
        cooperation_direction: "合作方向",
        company_name: "企业全称",
        address: "联系地址",

        province:'省份',
        city:'城市',
        license:'图片'
    },
})

//自定义校验规则
// VeeValidate.Validator.extend("agree", {
//     validate: (value) => {
//         return value;
//     },
//     getMessage: (field) => field + "必须同意",
// });
/*
        name: "",
        phone: "",
        main_product: "",
        cooperation_direction: "",
        company_name: "",
        address: "",
*/ 