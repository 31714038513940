import { reqAbout } from '@/api'
import { reqHonorDetail } from '@/api'

const state = {
    about:[],
    honorDetail:{}
}

const getters = {
    topInfo(state){
        return state.about[0]  || {}
    },
    firmIntro(state){
        return state.about[1]  || {}
    },
    history(state){
        return state.about[2]  || {}
    },
    mission(state){
        return state.about[3]  || {}
    },
    team(state){
        return state.about[4] || {}
    },
    expert(state){
        return state.about[5] || {}
    },
    honor(state){
        return state.about[6] || {}
    },
    honorDetailTop(state){
        return state.honorDetail.channel_content || []
    },
    honorDetailtypeList(state){
        return state.honorDetail.type_list || []
    },

    honorDetailContent(state){
        return state.honorDetail.content || []
    }
}

const actions = {
    async getAbout({ commit }) {
        let res = await reqAbout()
        if (res.code == 200) {
            commit('GETABOUT', res.content.content)
        }
    },
    async getHonorDetail({ commit }) {
        let res = await reqHonorDetail()
        if (res.code == 200) {
            commit('GETHONORDETAIL', res.content)
        }
    }
}

const mutations = {
    GETABOUT(state,about){
        state.about = about
    },
    GETHONORDETAIL(state,honorDetail){
        state.honorDetail = honorDetail
    }
}

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters
}