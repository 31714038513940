import { reqBusiness, reqJoinUs} from '@/api'

const state = {
    business:[],
    joinUs:[]
}

const getters = {
    businessTopInfo(state){
        return state.business[0] || {}
    },
    career(state){
        return state.business[1] || {}
    },
    advantage(state){
        return state.business[2] || {}
    },
    comprehensiveAdvantage(state){
        return state.business[4] || {}
    },
    joinRequest(state){
        return state.business[5] || {}
    },
    JoinUsTopInfo(state){
        return state.joinUs[0] || {}
    },
    JoinUsProcess(state){
        return state.joinUs[1] || {}
    }
}

const actions = {
    async getBusiness({ commit }) {
        let res = await reqBusiness()
        if (res.code == 200) {
            commit('GETBUSINESS', res.content.content)
        }
    },
    // 招商加盟-加盟申请页面
    async getJoinUs({ commit }) {
        let res = await reqJoinUs()
        if (res.code == 200) {
            commit('GETJOINUS', res.content.content)
        }
    },
}

const mutations = {
    GETBUSINESS(state,business){
        state.business = business
    },
    GETJOINUS(state,joinUs){
        state.joinUs = joinUs
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}