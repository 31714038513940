// search仓库
import { reqGetSearchInfo } from '@/api'
const state = {
    searchList:{}
}

const getters = {
    searchListData(state){
        // 如果没网，会返回undefined,所以至少需要返回一个空数组，
        return state.searchList.content || {}
    },
    // tags2(state) {
    //     return state.searchList.tags || []
    // }
}

const actions = {
    // params = {}:默认是空对象，传了实参就是实参的值
    async getSearchList({ commit }, query = {}) {
        let res = await reqGetSearchInfo(query)
        if (res.code == 200) {
            commit('GETSEARCHLIST', res)
            // console.log(res.content);
        }
    }
}

const mutations = {
    GETSEARCHLIST(state,searchList){
        state.searchList = searchList
    }
}

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters
}